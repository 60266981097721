"use client";
import {
  Dialog,
  DialogCancel,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  Button,
  toast,
} from "@openchatai/hoose/ui";
import {
  CheckCircle2,
  Code2,
  Crown,
  Loader2,
  ShieldCheck,
  Workflow,
  X,
  XCircle,
} from "lucide-react";
import Image from "next/image";
import { useAsyncFn } from "react-use";
import { createPaymentLink } from "@/data/payments";
import { useMemo } from "react";
import {
  ReadonlyURLSearchParams,
  useRouter,
  useSearchParams,
} from "next/navigation";
import _ from "lodash";
import { useUser } from "@/hooks/auth";
import { cn } from "@openchatai/hoose/utils";
import { useSetSearchParams } from "@/hooks/useSearchParams";

const PAYMENT_MODAL_KEY = "paying_modal";
const PAYMENT_RESULT_KEY = "payment";

function generateRedirectUrl() {
  const curr = new URL(window.location.href);
  const successUrl = new URL(curr.href);
  successUrl.searchParams.append(PAYMENT_RESULT_KEY, "true");
  const cancelUrl = new URL(curr.href);
  cancelUrl.searchParams.append(PAYMENT_RESULT_KEY, "false");
  [cancelUrl, successUrl].forEach((url) => {
    url.searchParams.set(PAYMENT_MODAL_KEY, "true");
  });
  return { successUrl: successUrl.href, cancelUrl: cancelUrl.href };
}

function usePaymentLink() {
  const [user] = useUser();
  const { cancelUrl, successUrl } = useMemo(() => generateRedirectUrl(), []);
  const [state, $createPaymentLink] = useAsyncFn(createPaymentLink, [user]);
  const router = useRouter();
  async function generatePaymentLink() {
    if (state.value?.data.checkout_url) {
      router.push(state.value.data.checkout_url);
      return;
    }
    const { data } = await $createPaymentLink({
      cancel_url: cancelUrl,
      success_url: successUrl,
    });
    if (data.checkout_url) {
      _.delay(() => router.push(data.checkout_url), 1000);
    } else {
      toast.error("Error");
    }
  }
  return [state, generatePaymentLink] as const;
}

export function PayButton({ className }: { className?: string }) {
  const [{ loading, value }, generatePaymentLink] = usePaymentLink();
  return (
    <Button
      className={cn(
        "relative shrink-0 overflow-hidden rounded disabled:opacity-80",
        className,
      )}
      disabled={loading}
      onClick={generatePaymentLink}
      size="fluid"
    >
      {value?.data.checkout_url && !loading ? (
        "Redirecting you to Stripe..."
      ) : (
        <>
          <span
            className={cn(
              "flex-center absolute inset-0",
              !loading
                ? "animate-in"
                : "opacity-0 animate-out slide-out-to-bottom-full",
            )}
          >
            <span className="text-sm font-semibold">Upgrade for $89/m </span>
          </span>
          <span
            className={cn(
              "flex-center absolute inset-0",
              loading
                ? "animate-in fade-in"
                : "opacity-0 animate-out fade-out-0",
            )}
          >
            <Loader2
              className="h-7 w-7 animate-spin text-white"
              strokeWidth="2"
            />
          </span>
        </>
      )}
    </Button>
  );
}

function PayingContent() {
  return (
    <div className="relative isolate col-span-full grid grid-cols-12 grid-rows-1">
      <DialogCancel className="absolute right-2 top-2 z-50 rounded-full bg-black/50 p-1.5 text-white">
        <X className="h-5 w-5" />
      </DialogCancel>
      <div className="col-span-full p-5 md:col-span-5">
        <div className="flex size-full flex-col items-start gap-5">
          <div className="w-full">
            <DialogTitle className="text-balance text-2xl font-bold">
              Upgrade to Premium
            </DialogTitle>
            <DialogDescription className="mt-2">
              OpenCopilot Premium will take your product experience to the next
              level
            </DialogDescription>
          </div>
          <div className="flex-1 overflow-auto text-base">
            <p className="mb-4 font-semibold text-accent-foreground">With:</p>
            <ul className="space-y-2 font-medium text-accent-foreground/80">
              <li>
                <Code2 className="inline-block size-5 text-primary" />
                <span className="ml-1">
                  {" "}
                  <b>Our latest planning engine </b> that can execute long flows
                  and bulk actions.{" "}
                </span>
              </li>
              <li>
                <Workflow className="inline-block size-5 text-primary" />
                <span className="ml-1">
                  Flows builder <b>that is powered by AI</b> so you can train
                  the copilot
                </span>
              </li>
              <li>
                <ShieldCheck className="inline-block size-5 text-primary" />
                <span className="ml-1">
                  {" "}
                  <b>1000 executable actions</b> and <b> 5.000 text queries</b>,
                  your user will not get lost anymore
                </span>
              </li>
              <li>
                <ShieldCheck className="inline-block size-5 text-primary" />
                <span className="ml-1">
                  {" "}
                  <b>Analytics</b> and <b> knowldgebase</b>, so your copilot is
                  deeply integrated with your business
                </span>
              </li>
            </ul>
          </div>
          <div className="flex-center w-full py-2">
            <PayButton />
          </div>
        </div>
      </div>
      <div className="relative col-span-7 overflow-hidden bg-accent">
        <div className="absolute inset-0 size-full scale-150 ">
          <div className="relative size-full">
            <Image
              alt=""
              fill
              className="object-fill"
              src="/15634887_5571278.svg"
            />
          </div>
        </div>
        <div className="relative hidden size-full">
          <span className="absolute left-0 top-0 size-72 -translate-x-1/2 -translate-y-1/2 rounded-full bg-rose-700" />
          <span className="absolute bottom-0 right-0 size-72 translate-x-1/2 translate-y-1/2 rounded-full bg-primary" />
        </div>
      </div>
    </div>
  );
}
function SuccessContent() {
  return (
    <div className="flex-center relative isolate col-span-full flex-col p-5">
      <div className="w-full text-center">
        <DialogTitle className="text-balance text-2xl font-bold">
          Thanks for subscribing!
        </DialogTitle>
        <DialogDescription className="mt-2 text-pretty">
          You are all set!
        </DialogDescription>
      </div>
      <div className="mt-5 text-emerald-600">
        <CheckCircle2 className="size-20" />
      </div>
    </div>
  );
}
function CanceledContent() {
  return (
    <div className="flex-center relative isolate col-span-full flex-col p-5">
      <div className="w-full text-center">
        <DialogTitle className="text-balance text-2xl font-bold">
          Payment canceled
        </DialogTitle>
        <DialogDescription className="mt-2 text-pretty">
          Your payment did not go through
        </DialogDescription>
      </div>
      <div className="mt-5 text-rose-600">
        <XCircle className="size-20" />
      </div>
    </div>
  );
}
export function PaymentModal() {
  const { searchParams: _sp, setSearchParams } = useSetSearchParams();
  let sp = useMemo(() => _sp ?? new ReadonlyURLSearchParams(), [_sp]);
  const [isSuccesseded, isCanceled] = [
    sp.get(PAYMENT_RESULT_KEY) === "true",
    sp.get(PAYMENT_RESULT_KEY) === "false",
  ];

  const isPaying = sp.has(PAYMENT_MODAL_KEY) && !isSuccesseded && !isCanceled;
  console.log(isPaying);
  const isOpen = sp.has(PAYMENT_MODAL_KEY) || sp.has(PAYMENT_RESULT_KEY);
  function handleCloseModal(open: boolean) {
    if (!open) {
      setSearchParams({
        [PAYMENT_MODAL_KEY]: undefined,
        [PAYMENT_RESULT_KEY]: undefined,
      });
    } else {
      setSearchParams({
        [PAYMENT_MODAL_KEY]: "true",
      });
    }
  }
  return (
    <div className="contents" suppressHydrationWarning>
      <Dialog open={isOpen} onOpenChange={handleCloseModal}>
        <DialogTrigger asChild>
          <Button className="rounded-full" variant="default" size="fit">
            <Crown className="h-5 w-5" />
          </Button>
        </DialogTrigger>
        <DialogContent
          className={cn(
            "m-0 grid max-h-[70svh] max-w-[90%] grid-cols-12 grid-rows-1 gap-0 overflow-hidden border-0 p-0 outline-none",
            isPaying ? "h-full  xl:max-w-4xl" : "h-fit max-w-sm",
          )}
        >
          {isPaying && <PayingContent />}
          {isSuccesseded && <SuccessContent />}
          {isCanceled && <CanceledContent />}
        </DialogContent>
      </Dialog>
    </div>
  );
}
